@mixin title-lg {
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -2%;
}
@mixin title-md {
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -2%;
}
@mixin title-sm {
    font-size: 30px;
    line-height: 38px;
}
@mixin title-xs {
    font-size: 24px;
    line-height: 32px;
}

@mixin text-xl {
    font-size: 20px;
    list-style: 30px;
}
@mixin text-lg {
    font-size: 18px;
    line-height: 28px;
}
@mixin text-md {
    font-size: 16px;
    line-height: 24px; 
}
@mixin text-sm {
    font-size: 14px;
    line-height: 20px;
}
@mixin text-xs {
    font-size: 12px;
    line-height: 18px;
}

@mixin weight-400 {
    font-weight: 400;
}
@mixin weight-500 {
    font-weight: 500;
}
@mixin weight-600 {
    font-weight: 600;
}
@mixin weight-700 {
    font-weight: 700;
}
